<template>
  <div class="card mb-4">
    <img class="card-img-top" :src="blog.image" alt="Card image cap" />
    <div class="card-body">
      <h4 class="text-blue text-link" @click.prevent="viewBlog">
        {{ blog.title }}
      </h4>
      <p class="card-text text-blue">{{ blog.summary }}</p>
      <small v-if="blog.rejected" class="text-danger"
        >REJECTED BY {{ blog.rejectedBy }}</small
      >
      <small v-else :class="blog.published ? 'text-success' : 'text-danger'">{{
        blog.published ? "PUBLISHED" : "PENDING"
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    blog: Object,
  },
  data() {
    return {
      blogLink: "",
    };
  },
  methods: {
    async viewBlog() {
      this.$store.commit("blogStatus", this.blog);
      await this.$store.dispatch("getWriterProfile", this.blog.writtenBy);
      this.$router.push(this.blogLink);
    },
  },
  mounted() {
    this.blogLink = `/blogs/${this.blog.id}`;
  },
};
</script>


<style scoped>
.text-link {
  text-decoration: none;
  color: #023b95;
}

.text-link:hover {
  text-decoration: underline;
  color: #023b95;
  cursor: pointer;
}
</style>
