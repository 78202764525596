<template>
  <div class="container">
    <h2 class="text-center text-blue">My Blogs</h2>
    <button class="btn btn-add">
      <router-link to="/blogs/new">Write A Blog</router-link>
    </button>
    <br /><br />
    <h3 class="text-blue text-center" v-if="!getMyBlogs.length">
      You don't have any blogs
    </h3>
    <div v-else class="row">
      <div
        v-for="(blog, index) in getMyBlogs"
        :key="index"
        class="col-md-4 col-sm-6 d-flex align-items-stretch"
      >
        <blog-card :blog="blog" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BlogCard from "./BlogCard.vue";

export default {
  name: "MyBlogs",
  components: { BlogCard },
  computed: { ...mapGetters(["getMyBlogs"]) },
  data() {
    return {};
  },
};
</script>

<style scoped>
.btn-add {
  background-color: #023b95;
  border-radius: 8px;
  padding: 10px;
  color: white;
}

.btn-add a {
  color: white;
  text-decoration: none;
}
</style>
